@use '@angular/material' as mat;

@mixin apply() {
  --mdc-protected-button-container-shape: var(--buttons-radius, 4px);
  vdms-hq-ui-button.disabled {
    pointer-events: none;
  }
  .mat-mdc-raised-button > .mat-icon {
    margin-right: 0;
    margin-left: 0;
  }
  .material-custom-button.mdc-button {
    @include mat.elevation(0);
    &.cdk-focused {
      @include mat.elevation(0);
    }
    min-width: 16px;
    padding: var(--buttons-padding, 8px 16px);

    .mdc-button__label {
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }
      white-space: nowrap;
    }

    &.icon-only {
      .mat-icon {
        margin: 0;
      }
    }

    .mat-mdc-progress-spinner {
      position: absolute;
      top: 8px;
      left: calc(50% - 10px);
    }
    &.mat-unthemed,
    &.mat-unthemed.mat-mdc-raised-button[disabled][disabled] {
      --mdc-protected-button-disabled-container-color: transparent;
      --mdc-protected-button-container-color: transparent;
    }
    &:not(.mat-unthemed).mat-primary {
      --mdc-protected-button-label-text-color: var(--primary-light-text);
    }

    &:not(.mat-unthemed).mat-accent {
      --mdc-protected-button-label-text-color: var(--btn-secondary-text);
    }

    &.size-full {
      width: 100%;
    }

    &.size-small {
      font-size: 12px;
      line-height: 12px;
      &.icon-only {
        padding: 2px;
        min-width: unset;
      }
    }

    &.size-fieldset {
      font-size: 10px;
      max-height: 20px;
      min-width: 20px;
      padding: 0 8px;

      .icon {
        font-size: 10px;
        margin-right: unset;
        margin-bottom: 2px;
        height: 10px;
        width: 10px;
        vertical-align: super;
      }
    }
  }
}
