@mixin apply() {
  --mdc-plain-tooltip-container-color: var(--bg-tooltip);
  --mdc-plain-tooltip-supporting-text-color: var(--text);
  .mat-mdc-tooltip,
  .mat-mdc-tooltip .mdc-tooltip__surface {
    // ability to use \n in tooltip
    white-space: pre-wrap;
    min-height: fit-content;
    min-width: fit-content;
    max-width: 80vw;
  }
}
